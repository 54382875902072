
/* MODULES */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { FormsModule }   from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

/* COMPONENTS */
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { ServicesComponent } from './pages/services/services.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { AboutComponent } from './pages/about/about.component';
import { HomeComponent } from './pages/home/home.component';
import { PaginationComponent } from './shared/pagination/pagination.component';

/* PIPES */
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { DomseguroPipe } from './pipes/domseguro.pipe';
import { NoImagePipe } from './pipes/no-image.pipe';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    NoImagePipe,
    DateTimeFormatPipe,
    DomseguroPipe,
    ServicesComponent,
    FaqsComponent,
    AboutComponent,
    HomeComponent,
    BlogComponent,
    BlogDetailComponent,
    PaginationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ContentLoaderModule,
    FormsModule,
    ClipboardModule,
    AutocompleteLibModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
