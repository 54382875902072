import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() totalPaginas: number;
  @Input() paginaActual: number;
  paginas: number[];

  constructor() { }

  ngOnInit(): void {
    this.paginas = new Array(this.totalPaginas)
        .fill(0)
        .map((_valor, indice) => 
          indice + 1
        );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.totalPaginas && changes.totalPaginas.currentValue && changes.totalPaginas.previousValue != changes.totalPaginas.currentValue) {
      this.totalPaginas = changes.totalPaginas.currentValue;
    }

    if (changes.paginaActual && changes.paginaActual.currentValue && changes.paginaActual.previousValue != changes.paginaActual.currentValue) {
      this.paginaActual = changes.paginaActual.currentValue;
    }

    this.paginas = new Array(this.totalPaginas)
        .fill(0)
        .map((_valor, indice) => 
          indice + 1
        );
  }
}