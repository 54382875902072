import { Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { JsService } from '../../services/js.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  slider: any;
  hideTheSlider: boolean = false;
  sliderLive: boolean;

  constructor(private element: ElementRef, public jsService: JsService,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnDestroy(): void {
    this.slider.revkill();
  }

  ngOnChanges() {
    if (this.hideTheSlider === true && this.slider) { 
        this.slider.revkill();
        this.sliderLive = false;
        setTimeout(() => this.sliderLive = true, 100);
    }
  }

  ngAfterViewInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.slider = (<any>window).$(this.element.nativeElement)
          .find('#rev_main')
          .revolution(this.getSliderOptions());
      }, 10);
    }
  }

  ngOnInit() {
    this.jsService.loadScript();
    window.scroll(0,0);
  } 

  getSliderOptions(): any {
    return {
      sliderType: "standard",
      jsFileLocation: "js/revolution/",
      sliderLayout: "fullscreen",
      dottedOverlay: "none",
      delay: 9000,
      navigation: {
          keyboardNavigation: "off",
          keyboard_direction: "horizontal",
          mouseScrollNavigation: "off",
          mouseScrollReverse: "default",
          onHoverStop: "off",
          touch: {
              touchenabled: "on",
              swipe_threshold: 75,
              swipe_min_touches: 1,
              swipe_direction: "horizontal",
              drag_block_vertical: false
          },
          bullets: {
              enable: true,
              hide_onmobile: true,
              style: "numbered",
              hide_onleave: false,
              hide_under: 767,
              direction: "vertical",
              h_align: "left",
              v_align: "center",
              h_offset: 20,
              v_offset: 0,
              space: 5,
              tmp: '<div class="tp-bullet-number"><span class="tp-count">{{param1}}</span><span class="tp-bullet-line"></span></div>'
          },
          arrows: {
              style: "",
              enable: false,
          }
      },
      viewPort: {
          enable: true,
          outof: "pause",
          visible_area: "80%",
          presize: false
      },
      responsiveLevels: [1240, 1024, 778, 480],
      visibilityLevels: [1240, 1024, 778, 480],
      gridwidth: [1140, 1024, 768, 480],
      gridheight: [660, 650, 600, 490],
      lazyType: "none",
      parallax: {
          type: "mouse",
          origo: "slidercenter",
          speed: 2000,
          speedbg: 0,
          speedls: 0,
          levels: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 20, 25, 55],
          disable_onmobile: "on"
      },
      shadow: 0,
      spinner: "off",
      stopLoop: "off",
      stopAfterLoops: -1,
      stopAtSlide: -1,
      shuffle: "off",
      autoHeight: "off",
      hideThumbsOnMobile: "off",
      hideSliderAtLimit: 0,
      hideCaptionAtLimit: 0,
      hideAllCaptionAtLimit: 0,
      debugMode: false,
      fallbacks: {
          simplifyAll: "off",
          nextSlideOnWindowFocus: "off",
          disableFocusListener: false,
      }
    };
  }

}
