import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { ArticuloModel } from '../models/articulo.model';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  ultArticulos: ArticuloModel[] = [];
  
  constructor( private http: HttpClient ) {}

  obtenerArticulo(accessToken: string, tipoBusqueda: string, uuid: string, idArticulo: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/blog/v1/articulo?tipoBusqueda=${tipoBusqueda}&uuid=${uuid}&idArticulo=${idArticulo}`, {headers: headers});
  }

  obtenerArticulos(accessToken: string, tipoBusqueda: string, uuid: string, limite: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });
    
    return this.http.get(
      `${environment.url}/blog/v1/articulos?tipoBusqueda=${tipoBusqueda}&uuid=${uuid}&limite=${limite}`, {headers: headers});
  }

  obtenerArticulosBusqueda(accessToken: string, uuid: string, titulo: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });
    
    return this.http.get(
      `${environment.url}/blog/v1/articulos/autocomplete?uuid=${uuid}&titulo=${titulo}`, {headers: headers});
  }

  obtenerComentarios(accessToken: string, idArticulo: number, uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/blog/v1/comentarios?idArticulo=${idArticulo}&uuid=${uuid}`, {headers: headers});
  }

  obtenerArticulosPag(accessToken: string, tipoBusqueda: string, uuid: string, limite: number, numPagina: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/blog/v1/articulos?tipoBusqueda=${tipoBusqueda}&uuid=${uuid}&limite=${limite}&numPagina=${numPagina}`, {headers: headers});
  }

  obtenerCategorias(accessToken: string, uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/blog/v1/catalogos/categorias?uuid=${uuid}`, {headers: headers});
  }

  obtenerPublicidad(accessToken: string, uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/publicidad/v1/empresas-publicidad?uuid=${uuid}&pagWeb=true`, {headers: headers});
  }

  setUltimosArticulos(ultArticulos: ArticuloModel[]): void {
    this.ultArticulos = ultArticulos;
  }

  getUltimosArticulos(): ArticuloModel[]{
    return this.ultArticulos;
  }

  getUltimosArticulosSize(): number{
    return this.ultArticulos.length;
  }

  obtenerArticulosPagPorCategoria(accessToken: string, idCategoria: number, uuid: string, limite: number, numPagina: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    });

    return this.http.get(
      `${environment.url}/blog/v1/articulos/categoria?uuid=${uuid}&idCategoria=${idCategoria}&limite=${limite}&numPagina=${numPagina}`, {headers: headers});
  }

}
