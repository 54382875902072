<!--Page Header-->
<section id="main-banner-page" class="position-relative page-header faq-header parallax section-nav-smooth">
    <div class="overlay overlay-dark opacity-6"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div class="page-titles whitecolor text-center padding_top padding_bottom">
                    <h2 class="font-xlight">¿Tienes alguna</h2>
                    <h2 class="font-bold">duda?</h2>
                    <h2 class="font-xlight"></h2>
                    <h3 class="font-light pt-2">Esta sección puede ayudarte</h3>
                </div>
            </div>
        </div>
        <div class="gradient-bg title-wrap">
            <div class="row">
                <div class="col-lg-12 col-md-12 whitecolor">
                    <h3 class="float-left">FAQ's</h3>
                    <ul class="breadcrumb top10 bottom10 float-right">
                        <li class="breadcrumb-item hover-light"><a [routerLink]="['/home']">Home</a></li>
                        <li class="breadcrumb-item hover-light">FAQ's</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Page Header ends -->

<!-- Faq -->
<section id="ourfaq" class="bglight position-relative padding">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center animated wow fadeIn" data-wow-delay="300ms">
                <h2 class="heading bottom30 darkcolor font-light2">Preguntas frecuentes
                    <span class="divider-center"></span>
                </h2>
                <div class="col-md-8 offset-md-2">
                    <p class="heading_space">No olvides leer algunas de las preguntas que frecuentemente nos hacen</p>
                </div>
            </div>
            <h2 class="d-none">Tabs</h2>
            <div class="col-md-12 col-sm-12">
                <div id="accordion">
                    <div class="card">
                        <div class="card-header">
                            <a class="card-link darkcolor" data-toggle="collapse" href="#collapseOne">¿Puedo incrementar notablemente mi recaudación propia?</a>
                        </div>
                        <div id="collapseOne" class="collapse show" data-parent="#accordion">
                            <div class="card-body">
                                <p class="bottom20">Cada municipio debe ser analizado individualmente.</p>
                                <p class="bottom20">Prácticamente todos los municipios pueden recaudar más y mejor en GESP (Gabinete de Expertos en Soluciones Públicas) podemos revisar la capacidad institucional de recaudación y la posibilidad contributiva de la sociedad.
                                    A partir de ello podemos diseñar e instrumentar, junto con ustedes, las medidas normativas, jurídicas, administrativas, tecnológicas y de comunicación necesarias para recaudar lo mejor posible. No olviden que si se
                                    recaudan más ingresos propios y si estos se registran y documentan correctamente, los mecanismos de coordinación hacendaria nacional privilegiarán que lleguen a su municipio más recursos federales, como resultado de
                                    esta intención de un esfuerzo recaudatoria municipal.
                                </p>
                                <p class="bottom20"><b>GESP les orienta cómo lograrlo.</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseTwo">¿Cuáles son mis funciones y como entregar buenos resultados?</a>
                        </div>
                        <div id="collapseTwo" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p class="bottom20">Definir las funciones, atribuciones y responsabilidades de cualquier servidor público municipal es una actividad urgente. En pocos municipios el Oficial Mayor desempeña su responsabilidad plenamente pero son importantísimas.
                                    Solamente hay que pensar que los recursos humanos, materiales y tecnológicos forman parte de la capacidad real institucional para ofrecer buenos resultados a la población.
                                </p>
                                <p class="bottom20">El Oficial Mayor debe saber administrar y desarrollar la plantilla de personal, también debe poder administrar, muy bien, el estado de uso y servicio de todos los bienes materiales, especialmente los sistemas y equipos
                                    tecnológicos. El Oficial Mayor es uno de los mejores defensores, junto con el Tesorero, del ejercicio del presupuesto y garantes de que los servicios que otorga el Ayuntamiento sean de buena calidad.
                                </p>
                                <p class="bottom20"><b>GESP les orienta cómo hacerlo.</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseThree">¿Mi Plan de Desarrollo Municipal es un requisito o sirve para algo? </a>
                        </div>
                        <div id="collapseThree" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p class="bottom20">Existe un Manual para ir construyendo estructuralmente el documento pero el Plan de Desarrollo Municipal solo lo pueden hacer bien quienes conocen el municipio, quienes lo recorrieron, quienes tomaron el pulso y la temperatura
                                    al ambiente socioeconómico y ambiental de primera mano, y quienes conocen la realidad actual. Si no es así, la administración municipal estará dando “palos de ciego” sin estrategia, sin plan, sin orden, sin instrumentación,
                                    sin seguimiento, sin evaluación, sin transparencia, sin consenso social y sin rendición de cuentas. Cuando se hace bien todo esto, se garantiza que la sociedad esté satisfecha con su gobierno.
                                </p>
                                <p class="bottom20"> “Ningún Médico puede recetar medicinas y tratamientos si no entrevista con detalle al paciente, y menos aún si no tiene a la mano sus análisis de laboratorio”. Así funciona con el Plan Municipal de Desarrollo. Necesitamos
                                    conocer las demandas y propuestas ciudadana del municipio, (ningún municipio es igual a otro) para traducirlas en respuestas y soluciones posibles y viables.
                                </p>
                                <p class="bottom20"><b>GESP los acompaña en hacer bien su Plan Municipal de Desarrollo…y a darle seguimiento.</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseFour">¿Funciona el Presupuesto Basado en Resultados? </a>
                        </div>
                        <div id="collapseFour" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p class="bottom20">Si funciona.</p>
                                <p class="bottom20">Cada presupuesto municipal tiene su tamaño y sus límites de recursos y cada paquete de resultados es diferente para cada municipio. No es lo mismo un municipio metropolitano, con todos los problemas propios de las ciudades,
                                    que un municipio con 13,500 habitantes con vocación productiva del sector primario.
                                </p>
                                <p class="bottom20">Se trata de identificar los alcances del presupuesto, definir los resultados a lograr en el periodo, y diseñar, en la Presidencia Municipal y junto a la participación ciudadana, no en oficinas remotas, cómo asociar los
                                    programas de acción a los rubros presupuestarios disponibles. Para lograr los resultados deseados.
                                </p>
                                <p class="bottom20"><b>GESP pone a su disposición su experiencia en PBR para que tenga éxito en el municipio.</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseFive">
                                Tengo la intensión de modernizar mi página de internet, ¿Me pueden ayudar con eso?
                            </a>
                        </div>
                        <div id="collapseFive" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p class="bottom20">GESP (Gabinete de Expertos en Soluciones Públicas), no desarrollamos sistemas directamente, pero tenemos la conexión con un grupo de jóvenes emprendedores de Hidalgo que comparte nuestros principios, misión y filosofía
                                    de trabajo con quien podemos contactarlos para este fin, con el plus de que brindamos orientación y capacitación técnica en temas de administración pública a este grupo de desarrolladores.
                                </p>
                                <p class="bottom20"><b>GESP con gusto les apoya para encontrar soluciones sobre estos temas.</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseSix">
                                ¿Se puede saber si el monto de participaciones que recibe el municipio es el correcto?
                            </a>
                        </div>
                        <div id="collapseSix" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p class="bottom20">En GESP (Gabinete de Expertos en Soluciones Públicas), podemos verificar los cálculos que se realizan para cada uno de los fondos que integran las participaciones que se ministran al estado y sus municipios, con seguimiento
                                    mensual, trimestral y anual a fin de que tengan plena certeza de que los procedimientos de cálculo y ministración recibida estén estrictamente apegados a fórmulas de distribución establecidas en ley.

                                </p>
                                <p class="bottom20"><b>GESP cuenta con la capacidad y experiencia en el cálculo de fórmulas de distribución.</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Faq ends -->

<!-- Contact US -->
<section id="stayconnect" class="bglight position-relative">
    <div class="container">
        <div class="contactus-wrapp">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="heading-title wow fadeInUp text-center text-md-left" data-wow-delay="300ms">
                        <h3 class="darkcolor bottom20">Escríbenos tus dudas y comentarios</h3>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12">
                    <form class="getin_form wow fadeInUp" data-wow-delay="400ms" onsubmit="return false;">
                        <div class="row">
                            <div class="col-md-12 col-sm-12" id="result"></div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="userName" class="d-none"></label>
                                    <input class="form-control" type="text" placeholder="Nombre" required id="userName" name="userName">
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="companyName" class="d-none"></label>
                                    <input class="form-control" type="text" placeholder="Ciudad ó Municipio" id="companyName" name="companyName">
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="email" class="d-none"></label>
                                    <input class="form-control" type="email" placeholder="Correo" required id="email" name="email">
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-2">
                                <div class="form-group">
                                    <label for="userMessage" class="d-none"></label>
                                    <textarea class="form-control" name="userMessage" rows="6" placeholder="Queremos conocer tus inquietudes"></textarea>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-6" style="justify-content: center; align-items: center; display: flex;">
                                <div class="col-md-6 col-sm-6">
                                    <button type="submit" class="button gradient-btn w-100" id="submit_btn">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact US ends -->