import { Component, OnInit } from '@angular/core';
import { JsService } from 'src/app/services/js.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(public jsService: JsService) { }

  ngOnInit() {
    this.jsService.loadScript();
    window.scroll(0,0);
  }

}
