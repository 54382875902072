<!--Site Footer Here-->
<footer id="site-footer" class=" bgprimary padding_top">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer_panel padding_bottom_half bottom20">
                    <a [routerLink]="['/home']" class="footer_logo"><img src="assets/images/logo-1.svg" alt="GESP" /> </a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer_panel padding_bottom_half bottom20">
                    <h3 class="whitecolor bottom25">Nuestro contacto</h3>
                    <div class="d-table w-100 address-item whitecolor bottom25">
                        <span class="d-table-cell align-middle"><i class="fas fa-mobile-alt"></i></span>
                        <p class="d-table-cell align-middle bottom0">
                            S/N <a class="d-block" href="mailto:contactogesp@gmail.com">contactogesp@gmail.com</a>
                        </p>
                    </div>
                    <ul class="social-icons white wow fadeInUp" data-wow-delay="300ms">
                        <li><a [href]="gespService.info.facebook" target="_blank" class=""><i class="fab fa-facebook-f"></i> </a> </li>
                        <li><a [href]="gespService.info.twitter" target="_blank" class=""><i class="fab fa-twitter"></i> </a> </li>
                        <li><a [href]="gespService.info.linkedin" target="_blank" class=""><i class="fab fa-linkedin-in"></i> </a> </li>
                        <li><a [href]="gespService.info.instagram" target="_blank" class=""><i class="fab fa-instagram"></i> </a> </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer_panel padding_bottom_half bottom20 pl-0 pl-lg-5">
                    <h3 class="whitecolor bottom25">Nuestros servicios</h3>
                    <ul class="links ul-nostyle">
                        <li><a [routerLink]="['/home']">Inicio</a></li>
                        <li><a [routerLink]="['/services']">Servicios</a></li>
                        <li><a [routerLink]="['/about']">¿Quiénes somos?</a></li>
                        <li><a [routerLink]="['/faqs']">FAQ's</a></li>
                        <li><a [routerLink]="['/blog']">Blog</a></li>
                        <li><a href="#">Recursos</a>
                            <ul class="links ul-nostyle">
                                <li>
                                    <a href="assets/docs/Presentacion.zip" download="Presentacion.zip" style="font-size: 11px;"> Presentacion.zip </a>
                                </li>
                                <li>
                                    <div>
                                        <a style="font-size: 11px;" href="#myVideo" data-fancybox>GESP.mp4</a>
                                        <video width="640" height="320" style="display:none;" id="myVideo" class="fancybox-video" controls>
                                            <source src="assets/docs/GESP.mp4" type="video/mp4">
                                           Tu navegador no soporta el formato de este video.
                                        </video>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer_panel padding_bottom_half bottom20">
                    <h3 class="whitecolor bottom25">Horario de atención</h3>
                    <p class="whitecolor bottom25"> Nuestro soporte está disponible para ayudarte siempre que lo requieras</p>
                    <ul class="hours_links whitecolor">
                        <li><span>Lunes-Viernes</span> <span>8:00-18:00</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <p class="whitecolor">Al hacer uso de esta página web usted esta de acuerdo con nuestro 
                    <a href="assets/docs/aviso-priv.pdf" target="_blank">
                       <b class="link">Aviso de privacidad</b>
                    </a>
               </p>
                <div class="footer_panel padding_bottom_half">
                    <a [href]="gespService.info.pagina_autor" target="_blank">
                        <span class="link">&copy; {{anio}} Powered by Grupo Fragy </span>
                    </a> <br>
                   <span class="whitecolor"> {{version}}</span>
                </div>
            </div>
        </div>
    </div>
</footer>