import { AutocompleteArticulo } from '../../models/autocomplete-articulo.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticuloModel, ArticuloModelGet } from 'src/app/models/articulo.model';
import { CategoriaModel } from 'src/app/models/categoria.model';
import { BlogService } from 'src/app/services/blog.service';
import { JsService } from 'src/app/services/js.service';
import { OauthService } from 'src/app/services/oauth.service';
import Swal from 'sweetalert2';
import { ArticuloBusquedaModel } from '../../models/articulo.model';
import { EmpresaPublicidadModel } from '../../models/publicidad.model';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit, OnDestroy {

  articulosPaginacion: ArticuloModelGet;
  articulos: ArticuloModel[] = [];
  ultArticulos: ArticuloModel[] = [];
  categorias: CategoriaModel[] = [];
  publicidad: EmpresaPublicidadModel[] = [];
  categoriaSelect: CategoriaModel =  new CategoriaModel();

  keyword: string = 'titulo';
  autocompleteArticulos: AutocompleteArticulo[] = [];
  articuloSelect: AutocompleteArticulo;

  totalPaginas: number;
  paginaActual: number;
  cargando: boolean = false;
  page: number;

  private token: string;
  private uuid: string;

  constructor(private blogService: BlogService, private oauthService: OauthService, private router: Router,
    private activatedRoute: ActivatedRoute, public jsService: JsService) { }

  ngOnInit() {

    this.jsService.loadScript();
    this.token = this.oauthService.getToken;
    this.uuid = sessionStorage.getItem('uuid');

    this.activatedRoute.paramMap.subscribe( params => {
      this.page = +params.get('page');
      if (!this.page || this.page <= 0) {
        this.page = 1;
      }

      if(this.token == null || this.token === "" ){
        this.uuid = this.oauthService.obtenerUuid();
        this.obtenerToken(this.page);
      } else {
        if(this.ultArticulos.length === 0){
          this.obtenerUltimosArticulos(this.token, this.uuid);
        }
        if(this.categorias.length === 0){
          this.obtenerCategorias(this.token,this.uuid);
        }
        if(this.autocompleteArticulos.length === 0){
          this.obtenerArticulosBusqueda(this.token,this.uuid);
        }
        if(this.publicidad.length === 0){
          this.obtenerPublicidad(this.token,this.uuid);
        }
        if (sessionStorage.getItem('categoriaBlog')) {
          this.categoriaSelect = JSON.parse(sessionStorage.getItem('categoriaBlog'));
          this.obtenerArticulosPorCategoria(this.token, this.categoriaSelect.idCategoria, this.uuid, this.page);
        } else {
          this.obtenerArticulos(this.token, this.uuid, this.page);
        }
      }
      this.cargando = true;
      window.scroll(0,0);
    });

  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('categoriaBlog');
  }

  selectCat(categoria : CategoriaModel) {
    this.page = 1;
    window.scroll(0,0);
    this.cargando = true;

    if (categoria.idCategoria == this.categoriaSelect.idCategoria) {
      this.categoriaSelect = new CategoriaModel();
      this.obtenerArticulos(this.token, this.uuid, this.page);
      sessionStorage.removeItem('categoriaBlog');
      return;
    }
    sessionStorage.setItem('categoriaBlog', JSON.stringify(categoria));
    this.obtenerArticulosPorCategoria(this.token, categoria.idCategoria, this.uuid, this.page);
    this.categoriaSelect = categoria;
  }

  private obtenerToken(page:number): void{
    this.oauthService.login()
    .subscribe(resp => {
      this.token = resp.access_token;
      this.oauthService.guardarToken(resp.access_token);
      this.obtenerArticulos(resp.access_token, this.uuid, page);
      this.obtenerUltimosArticulos(resp.access_token, this.uuid);
      this.obtenerCategorias(this.token,this.uuid);
      this.obtenerArticulosBusqueda(this.token,this.uuid);
      this.obtenerPublicidad(this.token,this.uuid);
    }, (err) => {
      if (err.status == 400 || err.status == 401) {
        this.lanzarError('Error de comunicación', err.error.detalles[0].mensaje.split(".", 1));
      } else if (err.status == 403) {
        this.lanzarError('Acceso denegado', err.error.detalles[0].mensaje);
      } else if (err.status == 500 || err.status == 503) {
        this.lanzarError('Error de comunicación', err.error.detalles[0].mensaje);
      } else {
        this.lanzarError('Error de servidor', "Estamos teniendo problemas de comunicación con nuestro servidor, "+
                          +"si el problema persiste por favor contactanos a contacto@grupofragy.com");
      }
    });
  }

  private obtenerArticulos(token:string, uuid:string, page:number): void{
    this.blogService.obtenerArticulosPag(token, 'paginacion', uuid, 4, page)
    .subscribe(resp => {
      let articulosResponse: ArticuloModelGet = resp.resultado;
      this.articulos = articulosResponse.articulos;
      this.totalPaginas = articulosResponse.totalPaginas;
      this.paginaActual = articulosResponse.numPagina;
      this.cargando = false;

    }, (err) =>{
      if (err.status == 401) {
        this.uuid = this.oauthService.obtenerUuid();
        this.obtenerToken(page);
      } 
    });
  }

  private obtenerUltimosArticulos(token:string, uuid:string): void{

    if(this.blogService.getUltimosArticulosSize() > 0){
      this.ultArticulos = this.blogService.getUltimosArticulos();
    }else{
      this.blogService.obtenerArticulos(token, 'ultimos', uuid, 5)
      .subscribe(resp => {
        let articulosResponse: ArticuloModelGet = resp.resultado;
        this.ultArticulos = articulosResponse.articulos;
        this.blogService.setUltimosArticulos(articulosResponse.articulos);
      }, (err) =>{
        if (err.status == 401) {
          this.uuid = this.oauthService.obtenerUuid();
          this.activatedRoute.paramMap.subscribe( params => {
            let page: number = +params.get('page');
            if (!page || page <= 0) {
              page = 1;
            }
            this.obtenerToken(page);
          });
        } 
      });
    }
  }

  private obtenerCategorias(token:string, uuid:string): void{
    this.blogService.obtenerCategorias(token, uuid)
    .subscribe(resp => {
      let categoriasResponse: CategoriaModel[] = resp.resultados;
      this.categorias = categoriasResponse;
    }, (err) =>{
      if (err.status == 401) {
        this.uuid = this.oauthService.obtenerUuid();
        this.activatedRoute.paramMap.subscribe( params => {
          let page: number = +params.get('page');
          if (!page || page <= 0) {
            page = 1;
          }
          this.obtenerToken(page);
        });
      } 
    });
  }

  private lanzarError(titulo: string, texto: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      text: texto
    });
  }

  private obtenerArticulosBusqueda(token: string, uuid: string) {
    this.blogService.obtenerArticulosBusqueda(token, uuid, "")
    .subscribe(resp => {
      let articulosResponse: ArticuloBusquedaModel[] = resp.resultados;
      this._crearDataAutocomplete(articulosResponse);
    }, (err) =>{
      if (err.status == 401) {
        this.uuid = this.oauthService.obtenerUuid();
        this.activatedRoute.paramMap.subscribe( params => {
          let page: number = +params.get('page');
          if (!page || page <= 0) {
            page = 1;
          }
          this.obtenerToken(page);
        });
      } 
    });
  }

  private obtenerPublicidad(token: string, uuid: string) {
    this.blogService.obtenerPublicidad(token, uuid)
    .subscribe(resp => {
      let publicidadResponse: EmpresaPublicidadModel[] = resp.resultados;
      this.publicidad = publicidadResponse;
    }, (err) =>{
      if (err.status == 401) {
        this.uuid = this.oauthService.obtenerUuid();
        this.activatedRoute.paramMap.subscribe( params => {
          let page: number = +params.get('page');
          if (!page || page <= 0) {
            page = 1;
          }
          this.obtenerToken(page);
        });
      } 
    });
  }

  private _crearDataAutocomplete(articulos: ArticuloBusquedaModel[]) {
    for (let articulo of articulos) {
      let autocompleteArticulo = new AutocompleteArticulo();

      autocompleteArticulo.titulo = this._eliminarDiacriticosEs(`${articulo.titulo}`);
      autocompleteArticulo.idArticulo = articulo.idArticulo;
      autocompleteArticulo.autor = this._eliminarDiacriticosEs(`${articulo.autor}`);

      this.autocompleteArticulos.push(autocompleteArticulo);
    }
  }

  private _eliminarDiacriticosEs(texto: string) {
    return texto.normalize('NFD')
      .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
      .normalize();
  }

  selectEvent(item: any) {
    this.articuloSelect = item;
    this.router.navigate(['/blog-detail', this.articuloSelect.idArticulo]);
  }

  clearedEvent() {
    this.articuloSelect = null;
  }

  private obtenerArticulosPorCategoria(token:string, idCategoria: number, uuid:string, page:number): void {
    this.blogService.obtenerArticulosPagPorCategoria(token, idCategoria , uuid, 4, page)
    .subscribe(resp => {
      let articulosResponse: ArticuloModelGet = resp.resultado;
      this.articulos = articulosResponse.articulos;
      this.totalPaginas = articulosResponse.totalPaginas;
      this.paginaActual = articulosResponse.numPagina;
      this.cargando = false;
    }, (err) =>{
      if (err.status == 401) {
        this.uuid = this.oauthService.obtenerUuid();
        this.obtenerToken(page);
      } 
    });
  }

}
