import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncriptarService {
  constructor( ) {}

  public encriptarTexto(texto: string): string{
    var encrypted = CryptoJS.AES.encrypt(texto.trim(), `${environment.encrypy_key}`, {
      mode: CryptoJS.mode.ECB
    });
    return encrypted.toString();
  }

  public desencriptarTexto(texto: string): string{
    return CryptoJS.AES.decrypt(texto, `${environment.encrypy_key}`, {
      mode: CryptoJS.mode.ECB
    }).toString(CryptoJS.enc.Utf8);
  }
}
