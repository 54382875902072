<!-- header -->
<header class="site-header" id="header">
    <nav class="navbar navbar-expand-lg transparent-bg static-nav">
        <div class="container">
            <a class="navbar-brand" [routerLink]="['/home']">
                <img src="assets/images/logo-1.svg" alt="logo" class="logo-default">
                <img src="assets//images/logo-2.svg" alt="logo" class="logo-scrolled">
            </a>
            <div class="collapse navbar-collapse">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/home']" [routerLinkActive]="['active']"> Inicio</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/services']" [routerLinkActive]="['active']"> Servicios </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/about']" [routerLinkActive]="['active']"> ¿Quiénes somos? </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/faqs']" [routerLinkActive]="['active']"> FAQ's </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/blog']" [routerLinkActive]="['active']"> Blog </a>
                    </li>
                    <li class="nav-item dropdown position-relative">
                        <a class="nav-link dropdown-toggle" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Recursos </a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="assets/docs/Presentacion.zip" download="Presentacion.zip">Presentacion.zip</a>
                            <div>
                                <a class="dropdown-item" href="#myVideo" data-fancybox>GESP.mp4</a>
                                <video width="640" height="320" style="display:none;" id="myVideo" class="fancybox-video" controls>
                                    <source src="assets/docs/GESP.mp4" type="video/mp4">
                                   Tu navegador no soporta el formato de este video.
                                </video>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <!--side menu open button-->
        <a href="javascript:void(0)" class="d-inline-block sidemenu_btn" id="sidemenu_toggle">
            <span></span> <span></span> <span></span>
        </a>
    </nav>
    <!-- side menu -->
    <div class="side-menu opacity-0 bg-yellow">
        <div class="overlay"></div>
        <div class="inner-wrapper">
            <span class="btn-close" id="btn_sideNavClose"><i></i><i></i></span>
            <nav class="side-nav w-100">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/home']"> Inicio </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/services']"> Servicios </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/about']"> ¿Quiénes somos? </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/faqs']"> FAQ's </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/blog']"> Blog </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link disabled">
                            Recursos <i class="fas fa-chevron-down"></i>
                        </a>
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" href="assets/docs/Presentacion.zip" download="Presentacion.zip">
                                    <p class="smenu">Presentacion.zip</p>
                                </a>
                            </li>
                            <li class="nav-item">
                                <div>
                                    <a class="nav-link" href="#myVideo" data-fancybox>
                                        <p class="smenu">GESP.mp4</p>
                                    </a>
                                    <video width="640" height="320" style="display:none;" id="myVideo" class="fancybox-video" controls>
                                        <source src="assets/docs/GESP.mp4" type="video/mp4">
                                       Tu navegador no soporta el formato de este video.
                                    </video>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </nav>
            <div class="side-footer w-100">
                <ul class="social-icons-simple white top40">
                    <li><a [href]="gespService.info.facebook" target="_blank" class=""><i class="fab fa-facebook-f"></i> </a> </li>
                    <li><a [href]="gespService.info.twitter" target="_blank" class=""><i class="fab fa-twitter"></i> </a> </li>
                    <li><a [href]="gespService.info.linkedin" target="_blank" class=""><i class="fab fa-linkedin-in"></i> </a> </li>
                    <li><a [href]="gespService.info.instagram" target="_blank" class=""><i class="fab fa-instagram"></i> </a> </li>
                </ul>
                <a [href]="gespService.info.pagina_autor" target="_blank">
                    <p class='whitecolor'>&copy; {{anio}} Powered by Grupo Fragy </p>
                </a>
            </div>
        </div>
    </div>
    <!-- End side menu -->
</header>
<!-- header -->