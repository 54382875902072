<nav>
    <ul *ngIf="paginas && paginas.length > 1" id="pagination" class="pagination justify-content-center top55 mb-4 mb-md-0 mb-sm- " style="overflow-x: scroll;">

        <li [ngClass]="paginaActual == 1 ? 'disabled page-item' : 'page-item'">
            <a class="page-link" [routerLink]="['/blog', 1]"><i class="fa fa-angle-double-left"></i></a>
        </li>

        <li class="page-item" *ngIf="paginaActual > 1">
            <a class="page-link" [routerLink]="['/blog', paginaActual-1]"><i class="fa fa-angle-left"></i></a>
        </li>

        <li *ngFor="let pagina of paginas" class="page-item" [ngClass]="pagina == paginaActual ? 'active' : ''">
            <span class="page-link" *ngIf="pagina == paginaActual">{{pagina}}</span>
            <a *ngIf="pagina != paginaActual" class="page-link" [routerLink]="['/blog', pagina]">{{pagina}}</a>
        </li>

        <li class="page-item" *ngIf="paginaActual < totalPaginas">
            <a class="page-link" [routerLink]="['/blog', paginaActual+1]"><i class="fa fa-angle-right"></i></a>
        </li>

        <li [ngClass]="paginaActual == totalPaginas ? 'disabled page-item' : 'page-item'">
            <a class="page-link" [routerLink]="['/blog', totalPaginas]"><i class="fa fa-angle-double-right"></i></a>
        </li>
    </ul>
</nav>