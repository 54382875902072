import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { EncriptarService } from './encriptar.service';

@Injectable({
  providedIn: 'root'
})
export class OauthService {

  token: string;
  uuid: number = +`${environment.uuid}`;

  constructor(private http: HttpClient, private encriptarService: EncriptarService) { }

  public get getToken(): string {
    if (this.token != null) {
      return this.token;
    } else if (this.token == null && sessionStorage.getItem('token') != null) {
      this.token = sessionStorage.getItem('token');
      return this.token;
    }
    return "";
  }

  login(): Observable<any>{

    let secret: string = this.encriptarService.desencriptarTexto(`${environment.secret_key_encrypt}`);

    const httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/x-www-form-urlencoded',
       'Authorization': 'Basic ' + btoa('gespublicas.com:'+secret)
     })
    }
    const body = new URLSearchParams();

    body.set('grant_type', 'client_credentials');
    body.set('uuid', this.obtenerUuid());
    
    return this.http.post(
      `${environment.url}/security/oauth/token`, body.toString(), httpOptions
      );
  }

  guardarToken(accessToken: string): void {
    this.token = accessToken;
    sessionStorage.setItem('token', accessToken);
  }

  obtenerUuid(): string {
    sessionStorage.setItem('uuid', this.uuid.toString());
    return this.uuid.toString();
  }

  obtenerToken(accessToken: string): any {
    if (accessToken != null) {
      return JSON.parse(atob(accessToken.split('.')[1]));
    }
    return null;
  }
}
