<!--Page Header-->
<section id="main-banner-page" class="position-relative page-header blog-header parallax section-nav-smooth">
    <div class="overlay overlay-dark opacity-7 z-index-1"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div class="page-titles whitecolor text-center padding_top padding_bottom">
                    <h2 class="font-xlight">No te pierdas</h2>
                    <h2 class="font-bold">Ningún articulo</h2>
                    <h3 class="font-light pt-2">Estamos seguros que te van a interesar</h3>
                </div>
            </div>
        </div>
        <div class="gradient-bg title-wrap" *ngIf="articulo">
            <div class="row">
                <div class="col-lg-12 col-md-12 whitecolor">
                    <h3 class="float-left">Blog</h3>
                    <ul class="breadcrumb top10 bottom10 float-right">
                        <li class="breadcrumb-item hover-light"><a [routerLink]="['/home']">Home</a></li>
                        <li class="breadcrumb-item hover-light"><a [routerLink]="['/blog']">Blog</a></li>
                        <li class="breadcrumb-item hover-light">{{articulo.titulo}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Page Header ends -->

<!-- Our Blogs -->
<section id="our-blog" class="bglight padding_top padding_bottom_half">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-7">
                <div class="news_item shadow-equal">
                    <div class="news_desc text-center text-md-left" *ngIf="articulo == null || cargando">
                        <facebook-content-loader></facebook-content-loader>
                        <facebook-content-loader></facebook-content-loader>
                        <facebook-content-loader></facebook-content-loader>
                        <facebook-content-loader></facebook-content-loader>
                        <facebook-content-loader></facebook-content-loader>
                    </div>
                    <div class="image" *ngIf="articulo">
                        <img style="width:100%; object-fit: cover;" [src]="articulo.imagenArticulo | noimage | domseguro" alt="image">
                    </div>
                    <div class="news_desc text-center text-md-left" *ngIf="articulo && !cargando">
                        <h2 class="text-capitalize font-normal darkcolor font-bold"><a>{{articulo.titulo}}</a></h2>
                        <ul class="meta-tags top20 bottom20">
                            <li><a href="javascript:void(0);"><i class="fas fa-calendar-alt"></i>{{articulo.fechaCreacion | dateTimeFormatFilter : "DD MMMM, Y" | titlecase }}</a></li>
                            <li>
                                <a href="javascript:void(0);"> <i class="far fa-user"></i> {{articulo.autor}} </a>
                            </li>
                        </ul>
                        <span *ngFor="let categoria of articulo.categorias" class="badge rounded-pill bottom30">{{categoria.descripcion}}</span>
                        <p class="card-text" [innerHTML]="articulo.contenido"></p>

                        <div class="row top30 bottom40" *ngIf="articulo">
                            <div class="col-6" style="display: flex; align-items: center;">
                                <a [routerLink]="['/blog']" class="button gradient-btn">Regresar</a>
                            </div>
                            <div class="col-6 post-shareit text-right text-11">
                                <strong class="d-inline-block align-middle mb-2">Compartir:</strong>
                                <div class="pb-10">
                                    <ul class="li-icons darkcolor social-icons mb-0 d-flex flex-row-reverse">
                                        <li><a style="cursor: pointer;" title="Copiar link" (cbOnSuccess)="copiado()" ngxClipboard [cbContent]="'https://gespublicas.com/#/blogdetail/' + articulo.idArticulo"><i class="fas fa-copy"></i></a></li>
                                        <li><a (click)="generarUrlCompartir()" [href]="'http://twitter.com/share?&url=' + urlCompartir" class="twitter-bg-hvr"><i class="fab fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a (click)="generarUrlCompartir()" [href]="'https://www.facebook.com/sharer/sharer.php?u=' + urlCompartir" class="facebook-bg-hvr"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="profile-authors heading_space">
                            <h3 *ngIf="comentarios.length > 1" class="text-capitalize defaultcolor bottom40">{{ comentarios.length }} Comentarios </h3>
                            <h3 *ngIf="comentarios.length === 1" class="text-capitalize defaultcolor bottom40">{{ comentarios.length }} Comentario </h3>
                            <h3 *ngIf="comentarios.length === 0" class="text-capitalize defaultcolor bottom40">Comentarios </h3>

                            <div *ngIf="comentarios.length === 0">
                                <div class="eny_profile bottom30 profile_text bottom0 top15">
                                    <p class="top10 bottom0">Aún no tenemos comentarios pero no pierdas la oportunidad de compartirnos tu opinión</p>
                                </div>
                            </div>

                            <div *ngFor="let comentario of comentarios; let i = index">
                                <div class="eny_profile bottom30 profile_text bottom0 top15">
                                    <h5><strong class="text-uppercase top10 bottom0"> {{comentario.autor}}</strong></h5>
                                    <p class="top10 bottom0 font-bold">{{comentario.fechaCreacion | dateTimeFormatFilter : "MMMM DD, y, h:mm:ss a" | titlecase }}</p>
                                    <p class="top10 bottom0">{{comentario.comentario}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="post-comment">
                            <div class="text-center text-md-left bottom20">
                                <h3 class="text-capitalize defaultcolor">Escribe tu comentario</h3>
                                <p class="bottom20 top20"><small class="fas fa-asterisk"></small> Tu correo no será públicado</p>
                            </div>
                            <form class="getin_form" data-wow-delay="400ms" (ngSubmit)="guardarComentario(comentariosForm)" role="form" #comentariosForm="ngForm">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group mb-20">
                                            <span *ngIf="comentariosForm.submitted && comentariosForm.controls['autor'].errors" class="text-danger animated fadeIn">
                                                El campo Nombre es obligatorio
                                            </span>
                                            <span>&nbsp;</span>
                                            <input type="text" class="form-control" name="autor" placeholder="Nombre" [(ngModel)]="comentarioRequest.autor" required>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <span *ngIf="comentariosForm.submitted && comentariosForm.controls['email'].errors" class="text-danger animated fadeIn">
                                                El campo Email es invalido
                                            </span>
                                            <span>&nbsp;</span>
                                            <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" class="form-control" name="email" placeholder="Email" [(ngModel)]="comentarioRequest.email">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <span *ngIf="comentariosForm.submitted && comentariosForm.controls['comentario'].errors" class="text-danger animated fadeIn">
                                            El campo Comentario es obligatorio
                                        </span>
                                        <textarea placeholder="Comentario" name="comentario" [(ngModel)]="comentarioRequest.comentario" class="form-control" required></textarea>
                                        <div class="text-1 text-11 margin-sm">
                                            <button type="submit" class="button gradient-btn">Enviar comentario</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-5">
                <aside class="sidebar whitebox mt-5 mt-md-0">
                    <div class="widget heading_space_half wow fadeIn" *ngIf="ultArticulos.length === 0 || cargando">
                        <bullet-list-content-loader></bullet-list-content-loader>
                        <bullet-list-content-loader></bullet-list-content-loader>
                    </div>
                    <div class="widget heading_space_half wow fadeIn" data-wow-delay="350ms" *ngIf="ultArticulos.length > 0 && !cargando">
                        <h4 class="text-capitalize darkcolor bottom20 text-center text-md-left">Últimos articulos</h4>
                        <div class="single_post" *ngFor="let articulo of ultArticulos; let i = index">
                            <a [routerLink]="['/blog-detail', articulo.idArticulo]" class="post"><img style="height:50px; width: 50px; object-fit: cover;" [src]="articulo.imagenArticulo | noimage | domseguro"></a>
                            <div class="text">
                                <a [routerLink]="['/blog-detail', articulo.idArticulo]">{{articulo.titulo}}</a>
                                <span>{{articulo.fechaCreacion | dateTimeFormatFilter : "MMMM DD, y" | titlecase }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="widget heading_space_half wow fadeIn text-center text-md-left" *ngIf="publicidad.length === 0 || cargando">
                        <bullet-list-content-loader></bullet-list-content-loader>
                    </div>
                    <div class="widget heading_space_half wow fadeIn text-center text-md-left" *ngIf="publicidad.length === 0 || cargando">
                        <bullet-list-content-loader></bullet-list-content-loader>
                    </div>
                    <div *ngFor="let anuncio of publicidad; let i = index;">
                        <div class="heading_space_half wow fadeIn" data-wow-delay="400ms">
                            <a [href]="anuncio.publicidad[0].url" target="_blank">
                                <img [src]="anuncio.publicidad[0].imagenesPublicidad[0] | noimage | domseguro" alt="publicidad" class="border_radius bottom40 text-center">
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</section>
<!--Our Blog Ends-->