export class ComentarioWebSocketModel {

    uuid:           string;
    idArticulo:     number;
    comentarios:    ComentarioModel[];
}

export class ComentarioModel {

    idComentario:   number;
    comentario:     string;
    email:          string;
    autor:          string;
    fechaCreacion:  Date;
}