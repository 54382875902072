<!--Page Header-->
<section id="main-banner-page" class="position-relative page-header about-header parallax section-nav-smooth">
    <div class="overlay overlay-dark opacity-6"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div class="page-titles whitecolor text-center padding_top padding_bottom">
                    <h2 class="font-xlight">Conoce Nuestro</h2>
                    <h2 class="font-bold">Equipo de</h2>
                    <h2 class="font-xlight">Profesionales Expertos</h2>
                    <h3 class="font-light pt-2"></h3>
                </div>
            </div>
        </div>
        <div class="gradient-bg title-wrap">
            <div class="row">
                <div class="col-lg-12 col-md-12 whitecolor">
                    <h3 class="float-left">¿Quiénes somos?</h3>
                    <ul class="breadcrumb top10 bottom10 float-right">
                        <li class="breadcrumb-item hover-light"><a [routerLink]="['/home']">Home</a></li>
                        <li class="breadcrumb-item hover-light">¿Qui&énes somos?</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Page Header ends -->
<!-- About us -->
<section id="aboutus" class="padding_top padding_bottom">
    <div class="container aboutus">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-5 padding_bottom_half">
                <div class="image"><img alt="SEO" src="assets/images/aboutus.jpg"></div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6 padding_bottom_half text-center text-md-left">
                <h2 class="darkcolor font-normal bottom30">¿Quiénes somos? </h2>
                <p class="bottom35">Somos un gabinete multidisciplinario de profesionales con amplia experiencia en la administración pública en el estado de Hidalgo que se han reunido con el objetivo de poner sus conocimientos al servicio de gestiones administrativas.
                </p>
            </div>
        </div>
        <!--Customers Review Slider-->
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center animated wow fadeIn" data-wow-delay="300ms">
                    <h2 class="heading bottom45 darkcolor font-weight-normal"><span class="font-xlight">Nuestra</span> Misión <span class="divider-center"></span></h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div id="testimonial-main-slider" class="owl-carousel">
                        <!--item 1-->
                        <div class="item">
                            <div class="testimonial-wrapp">
                                <span class="quoted"><i class="fas fa-bullseye"></i></span>
                                <div class="testimonial-text">
                                    <p class="bottom40">Apoyar las tareas administrativas de los municipios de Hidalgo, poniendo a su disposición información, orientación y asesoría, en aras de lograr gestiones más eficientes que alcancen mayores resultados en favor del
                                        bienestar de sus poblaciones.</p>
                                </div>
                            </div>
                        </div>
                        <!--item 2-->
                        <div class="item">
                            <div class="testimonial-wrapp">
                                <span class="quoted"><i class="fas fa-diagnoses"></i></span>
                                <div class="testimonial-text">
                                    <p class="bottom40">Efectuar análisis generales y específicos a bajos costos que apoyen la eficiencia en los procesos y la toma de decisiones públicas.</p>
                                </div>
                            </div>
                        </div>
                        <!--item 3-->
                        <div class="item">
                            <div class="testimonial-wrapp">
                                <span class="quoted"><i class="fas fa-eye"></i></span>
                                <div class="testimonial-text">
                                    <p class="bottom40">Con observancia a la ley y a la norma como principio superior, acompañar los procesos financieros, administrativos y socioeconomicos en las administraciones.</p>
                                </div>
                            </div>
                        </div>
                        <!--item 4-->
                        <div class="item">
                            <div class="testimonial-wrapp">
                                <span class="quoted"><i class="fas fa-balance-scale"></i></span>
                                <div class="testimonial-text">
                                    <p class="bottom40">Nuestros servicios de información y asesoría no forman parte de ninguna dependencia gubernamental, partido político o asociación, por ende, no responden a intereses de ninguna índole, más que el aspecto rigurosamente
                                        teórico y técnico, por lo que la objetividad es el principio básico de nuestros trabajos.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Customers Review-->
    </div>
</section>
<!-- About us ends -->

<!-- Testimonials -->
<section id="our-testimonial" class="bglight">
    <div class="bgprimary">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12 col-md-12 text-center">
                    <div class="heading-title wow fadeInUp padding_testi" data-wow-delay="300ms">
                        <span class="whitecolor">Años y años de experiencia</span>
                        <h2 class="whitecolor font-normal">Respaldan nuestro trabajo</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="owl-carousel" id="testimonial-slider">
            <!--item 1-->
            <div class="item testi-box">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12 text-center">
                        <div class="testimonial-round d-inline-block">
                            <img src="assets/images/team-1.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12  text-center">
                        <h4 class="defaultcolor font-light top15"><a href="#.">Pablo Ángeles</a></h4>
                        <p>Más de 30 años de experiencia</p>
                        <p class="bottom15 testimional-para top15 w-50 ml-auto mr-auto text-justify"> <i class="fas fa-caret-right"></i> Catedrático: “Contabilidad”, “Análisis de Sistemas”, “Administración de Recursos Informáticos”, “Ingeniería Económica”, “Presupuesto Público”, “Administración”. “Administración de Proyectos Informáticos".
                            <br><i class="fas fa-caret-right"></i> Política Presupuestal, Estadística e Informática, Estudios Regionales, Estadística, Modernización y Sistemas, Planeación Estatal de Desarrollo, Evaluación Sectorial.
                        </p>
                    </div>
                </div>
            </div>
            <!--item 2 -->
            <div class="item testi-box">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12 text-center">
                        <div class="testimonial-round d-inline-block">
                            <img src="assets/images/team-2.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12  text-center">
                        <h4 class="defaultcolor font-light top15"><a href="#.">Jesus Tamayo</a></h4>
                        <p>Más de 50 años de experiencia</p>
                        <p class="bottom15 testimional-para top15 w-50 ml-auto mr-auto text-justify"> <i class="fas fa-caret-right"></i> Catedrático: UNAM, UAEM.
                            <br><i class="fas fa-caret-right"></i> Política Internacional, Estudios Regionales, Investigador Nacional, Planeación Urbana, Desarrollo Regional, Planes Regionales de Desarrollo, Planeación Educativa, Asesor Metropolitano,
                            Programas Municipales, Programas de Desarrollo Sustentable.
                        </p>
                    </div>
                </div>
            </div>
            <!--item 3 -->
            <div class="item testi-box">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12 text-center">
                        <div class="testimonial-round d-inline-block">
                            <img src="assets/images/team-3.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12  text-center">
                        <h4 class="defaultcolor font-light top15"><a href="#.">Gustavo Jusue</a></h4>
                        <p>Más de 30 años de experiencia</p>
                        <p class="bottom15 testimional-para top15 w-50 ml-auto mr-auto text-justify"> <i class="fas fa-caret-right"></i> Catedrático: Física UAEH.
                            <br><i class="fas fa-caret-right"></i> Planeación, Apoyo Técnico, Estadística, Integración de Información, Soporte Técnico, Programas de Desarrollo Productivo, Organización e Integración de Información, Integración de Sistemas
                            de Información , Apoyo a Proyectos de Inversión Rural, Activos Productivos, Evaluación y Seguimiento.
                        </p>
                    </div>
                </div>
            </div>
            <!--item 4 -->
            <div class="item testi-box">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12 text-center">
                        <div class="testimonial-round d-inline-block">
                            <img src="assets/images/team-4.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12  text-center">
                        <h4 class="defaultcolor font-light top15"><a href="#.">Miguel Zerón</a></h4>
                        <p>Más de 40 años de experiencia</p>
                        <p class="bottom15 testimional-para top15 w-50 ml-auto mr-auto text-justify"> <i class="fas fa-caret-right"></i> Catedrático: UNAM, IPN, UAEH.
                            <br><i class="fas fa-caret-right"></i> Ferrocarriles Nacionales, Tendencias de Crecimiento en Ciudades de México, Atención a la Ciudadanía, Tarifas de Agua Potable, Financiamiento Público, Atención a Comunidades Marginadas,
                            Enlace Institucional en Municipios, Fomento Educativo, Asesor Legislativo.
                        </p>
                    </div>
                </div>
            </div>
            <!--item 5 -->
            <div class="item testi-box">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12 text-center">
                        <div class="testimonial-round d-inline-block">
                            <img src="assets/images/team-5.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12  text-center">
                        <h4 class="defaultcolor font-light top15"><a href="#.">César Gómez</a></h4>
                        <p>Más de 45 años de experiencia</p>
                        <p class="bottom15 testimional-para top15 w-50 ml-auto mr-auto text-justify"> <i class="fas fa-caret-right"></i> Catedrático: “Desarrollo y Planificación", “Coordinación de Investigación Económica y Social”, ”Finanzas Publicas”, ”UNAM".
                            <br><i class="fas fa-caret-right"></i> Investigador de Estudios Económicos, Proyectos Internacionales, Política Económica, Servicios Nacionales de Estadística, Control de Gestión, Informe de Gobierno, Plan Nacional de Desarrollo,
                            Control y Evaluación Financiera, Abastos Populares, Planeación, Estudios Estatales y Regionales, Manejo de Información Estadística, Indicadores, Metodología del Marco Lógico.
                        </p>
                    </div>
                </div>
            </div>
            <!--item 6 -->
            <div class="item testi-box">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12 text-center">
                        <div class="testimonial-round d-inline-block">
                            <img src="assets/images/team-6.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12  text-center">
                        <h4 class="defaultcolor font-light top15"><a href="#.">Francisco Ronquillo</a></h4>
                        <p>Más de 30 años de experiencia</p>
                        <p class="bottom15 testimional-para top15 w-50 ml-auto mr-auto text-justify"> <i class="fas fa-caret-right"></i> Catedrático: Economía y Sociología UAEH.
                            <br><i class="fas fa-caret-right"></i> Integración de Presupuestos de Egresos, Distribución de Recursos a Municipios, Elaboración de Cuenta Pública, Integración de Planes de Desarrollo, Programación, Informe de Gobierno, Matrices
                            de Indicadores, Transparencia, Armonización Presupuestal y Contable, Planeación Financiera.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--testimonials end-->

<!-- Faq -->
<section id="ourfaq" class="bglight position-relative padding">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center animated wow fadeIn" data-wow-delay="300ms">
                <h2 class="heading bottom30 darkcolor font-light2"><span class="font-normal">Ventajas</span> de contratar nuestros servicios
                    <span class="divider-center"></span>
                </h2>
            </div>
            <h2 class="d-none">Tabs</h2>
            <div class="col-md-12 col-sm-12">
                <div id="accordion">
                    <div class="card">
                        <div class="card-header">
                            <a class="card-link darkcolor" data-toggle="collapse" href="#collapseOne">Evitar hacer crecer innecesariamente al aparato administrativo</a>
                        </div>
                        <div id="collapseOne" class="collapse show" data-parent="#accordion">
                            <div class="card-body">
                                <p class="bottom20">Hoy día la capacidad de maniobra que enfrentan los municipios es reducida producto de elevados gastos de operación, que supone la alta proporción del pago de nómina de los trabajadores, lo que implica, sueldos, gratificaciones,
                                    seguridad social, indemnizaciones, impuestos, entre otros muchos más requerimientos. Apoyarse de profesionales en la conducción administrativa, reduce los impactos en gastos de servicios personales y su carga administrativa.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseTwo">Completa receptividad a sus necesidades administrativas</a>
                        </div>
                        <div id="collapseTwo" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p>A partir de la transmisión de la necesidad y elaboración de un diagnóstico objetivo apoyar la definición de objetivos y estrategias de interés de la administración municipal, así como la de sus procesos administrativos.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseThree">Posibilidad real de hacer eficientes los procesos administrativos </a>
                        </div>
                        <div id="collapseThree" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p>Existe un elevado número de empresas en el mercado que ofrecen servicios especializados, pero con el inconveniente de un alto costo y algunos de dudosa operatividad, nuestros servicios toman en cuenta las limitaciones presupuestales
                                    que enfrentan los municipios y la apremiante necesidad de ofrecer resultados.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseFour">Incorporar criterios técnicos a la toma de decisiones </a>
                        </div>
                        <div id="collapseFour" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p>Muchas decisiones se toman sin el sustento de criterios técnicos lo que hace que el proceso administrativo pierda su posibilidad de mayores impactos o resultados.</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <a class="collapsed card-link darkcolor" data-toggle="collapse" href="#collapseFive">Estricto apego al marco normativo vigente </a>
                        </div>
                        <div id="collapseFive" class="collapse" data-parent="#accordion">
                            <div class="card-body">
                                <p>La tranquilidad de una gestión apegada a derecho, que cumpla oportunamente con sus obligaciones normativas y evite complicaciones innecesarias durante y después de la gestión administrativa del gobernante.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Faq ends -->