import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebSocketsService {

  constructor(private http: HttpClient) { }

  public getPathSockJS(): string {
    return `${environment.url}/wsBlog/v1`;
  }
}
