import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticuloModel, ArticuloModelGet } from 'src/app/models/articulo.model';
import { ComentarioModel, ComentarioWebSocketModel } from 'src/app/models/comentario.model';
import { BlogService } from 'src/app/services/blog.service';
import { OauthService } from 'src/app/services/oauth.service';
import Swal from 'sweetalert2';

import { Client } from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { NgForm } from '@angular/forms';
import { ResponseModel } from '../../models/response.model';
import { WebSocketsService } from '../../services/websockets.service';
import { EmpresaPublicidadModel } from 'src/app/models/publicidad.model';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {
  
  comentariosPost: ComentarioWebSocketModel = new ComentarioWebSocketModel();
  comentarioRequest: ComentarioModel = new ComentarioModel();

  ultArticulos: ArticuloModel[] = [];
  comentarios: ComentarioModel[] = [];
  publicidad: EmpresaPublicidadModel[] = [];
  articulo: ArticuloModel;

  private token: string;
  private uuid: string;
  private idArticulo: number;
  cargando: boolean = false;

  urlCompartir: string;

  private client: Client;

  constructor(private blogService: BlogService, private oauthService: OauthService, 
    private webSocketsService: WebSocketsService, private activatedRoute: ActivatedRoute) { 

  }

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe( params => {
      
      this.idArticulo = +params.get('idArticulo');
      this.token = this.oauthService.getToken;
      this.uuid = sessionStorage.getItem('uuid');

      this.comentarios = [];

      this.client = new Client();
      this.client.webSocketFactory = () => {
        return new SockJS(this.webSocketsService.getPathSockJS());
      }

      this.client.activate();

      this.client.onConnect = (frame) => {
        this.client.subscribe('/subs/comentarios', e => {
          let respuesta: ResponseModel = JSON.parse(e.body) as ResponseModel;
          if(respuesta.resultado){
            let comentario: ComentarioModel = respuesta.resultado;
            this.comentarios.push(comentario); 
          }
        });
      }

      if(this.token == null || this.token === "" ){
        this.cargando = true;
        this.uuid = this.oauthService.obtenerUuid();
        this.obtenerToken(this.idArticulo);
        window.scroll(0,0);
      } else {
        this.cargando = true;
        if(this.ultArticulos.length === 0){
          this.obtenerUltimosArticulos(this.token, this.uuid, this.idArticulo);
        }
        if(this.publicidad.length === 0){
          this.obtenerPublicidad(this.token,this.uuid);
        }
        this.obtenerArticulo(this.token, this.uuid, this.idArticulo);
        this.obtenerComentarios(this.token, this.idArticulo, this.uuid);
        window.scroll(0,0);
      }

    });
  }

  guardarComentario(form: NgForm){

    if (form.invalid) {
      return;
    }
    
    if(!this.client.connected){
      this.comentarioNoEnviado();
    }else{
      this.comentariosPost.idArticulo = this.idArticulo;
      this.comentariosPost.uuid = this.uuid;
      this.comentariosPost.comentarios = [];
      this.comentariosPost.comentarios.push(this.comentarioRequest);

      this.client.publish({ destination: '/send/comentarios', body: JSON.stringify(this.comentariosPost) });

      this.comentarioRequest = new ComentarioModel();
      this.comentariosPost.comentarios = [];

      form.resetForm();

      this.comentarioEnviado();
    }
  }

  private obtenerToken(idArticulo: number): void{
    this.oauthService.login()
    .subscribe(resp => {
      this.token = resp.access_token;
      this.oauthService.guardarToken(resp.access_token);
      this.obtenerArticulo(resp.access_token, this.uuid, idArticulo);
      this.obtenerUltimosArticulos(resp.access_token, this.uuid, idArticulo);
      this.obtenerComentarios(resp.access_token, this.idArticulo, this.uuid);
      this.obtenerPublicidad(this.token,this.uuid);
    }, (err) => {
      if (err.status == 400 || err.status == 401) {
        this.lanzarError('Error de comunicación', err.error.detalles[0].mensaje.split(".", 1));
      } else if (err.status == 403) {
        this.lanzarError('Acceso denegado', err.error.detalles[0].mensaje);
      } else if (err.status == 500 || err.status == 503) {
        this.lanzarError('Error de comunicación', err.error.detalles[0].mensaje);
      } else {
        this.lanzarError('Error de servidor', "Estamos teniendo problemas de comunicación con nuestro servidor, "+
                          +"si el problema persiste por favor contactanos a contacto@grupofragy.com");
      }
    });
  }

  private obtenerArticulo(token:string, uuid:string, idArticulo:number): void{
    this.blogService.obtenerArticulo(token, 'id', uuid, idArticulo)
    .subscribe(resp => {
      this.articulo = resp.resultado;
      this.cargando = false;
    }, (err) =>{
      if (err.status == 401) {
        this.uuid = this.oauthService.obtenerUuid();
        this.obtenerToken(idArticulo);
      } 
    });
  }

  private obtenerUltimosArticulos(token:string, uuid:string, idArticulo: number): void{

    if(this.blogService.getUltimosArticulosSize() > 0){
      this.ultArticulos = this.blogService.getUltimosArticulos();
    }else{
      this.blogService.obtenerArticulos(token, 'ultimos', uuid, 5)
      .subscribe(resp => {
        let articulosResponse: ArticuloModelGet = resp.resultado;
        this.ultArticulos = articulosResponse.articulos;
        this.blogService.setUltimosArticulos(articulosResponse.articulos);
      }, (err) =>{
        if (err.status == 401) {
          this.uuid = this.oauthService.obtenerUuid();
          this.obtenerToken(idArticulo);
        } 
      });
    }
  }

  private obtenerComentarios(token:string, idArticulo: number, uuid:string): void{
    this.blogService.obtenerComentarios(token, idArticulo, uuid)
    .subscribe(resp => {
      let comentariosResponse: ComentarioWebSocketModel = resp.resultado;
      this.comentarios = comentariosResponse.comentarios;
    }, (err) =>{
      if (err.status == 401) {
        this.uuid = this.oauthService.obtenerUuid();
        this.obtenerToken(idArticulo);
      }
    });
  }

  private obtenerPublicidad(token: string, uuid: string) {
    this.blogService.obtenerPublicidad(token, uuid)
    .subscribe(resp => {
      let publicidadResponse: EmpresaPublicidadModel[] = resp.resultados;
      this.publicidad = publicidadResponse;
    }, (err) =>{
      if (err.status == 401) {
        this.uuid = this.oauthService.obtenerUuid();
        this.activatedRoute.paramMap.subscribe( params => {
          let page: number = +params.get('page');
          if (!page || page <= 0) {
            page = 1;
          }
          this.obtenerToken(page);
        });
      } 
    });
  }

  private lanzarError(titulo: string, texto: string) {
    Swal.fire({
      icon: 'error',
      title: titulo,
      text: texto
    });
  }

  private comentarioNoEnviado() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 2000
    })
    
    Toast.fire({
      icon: 'error',
      title: '¡Lo sentimos! No se puedo enviar tu comentario'
    })
  }

  private comentarioEnviado() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 2000
    })
    
    Toast.fire({
      icon: 'success',
      title: '¡Muchas Gracias! Hemos recibido tu comentario'
    })
  }

  public generarUrlCompartir(): void{ 

    if(this.articulo != null){
      let idArticulo = this.articulo.idArticulo;
      let dominioGesp = "https://gespublicas.com/#/blog-detail/"+idArticulo;
      this.urlCompartir = encodeURIComponent(dominioGesp);
    }
  }

  public copiado() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'center',
      showConfirmButton: false,
      timer: 2000
    })
    
    Toast.fire({
      icon: 'success',
      title: '¡Link copiado!'
    })
  }

}
