import { Component, OnInit } from '@angular/core';
import { GespService } from 'src/app/services/gesp.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  anio: number = new Date().getFullYear();
  
  constructor(public gespService: GespService) { }

  ngOnInit() {
  }

}
