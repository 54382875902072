<!--Page Header-->
<section id="main-banner-page" class="position-relative page-header service-header parallax section-nav-smooth">
    <div class="overlay overlay-dark opacity-6"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div class="page-titles whitecolor text-center padding_top padding_bottom">
                    <h2 class="font-xlight">Orientación Técnica y</h2>
                    <h2 class="font-bold">Servicios de Asesoría</h2>
                </div>
            </div>
        </div>
        <div class="gradient-bg title-wrap">
            <div class="row">
                <div class="col-lg-12 col-md-12 whitecolor">
                    <h3 class="float-left">Servicios</h3>
                    <ul class="breadcrumb top10 bottom10 float-right">
                        <li class="breadcrumb-item hover-light"><a [routerLink]="['/home']">Inicio</a></li>
                        <li class="breadcrumb-item hover-light">Servicios</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Page Header ends -->

<!--timeline sec start-->
<div class="timeline-sec" id="timeline-sec">
    <div class="timeline-overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 text-center">
                <div class="heading-title wow fadeInUp" data-wow-delay="300ms">
                    <span>Conoce los temas en los que </span>
                    <h2 class="font-normal">Te podemos asesorar </h2> <br><br>
                </div>
            </div>
            <div class="col-12">
                <ul class="timeline">
                    <div class="row timeline-year">
                        <div class="col-12">
                            <div class="t-year">
                                <h4 class="heading"></h4>
                            </div>
                        </div>
                    </div>
                    <li class="timeline-block simple-timeline wow fadeInLeft">
                        <div class="event-details">
                            <h4 class="event-heading">Administración Pública Municipal</h4> <br>
                            <p class="text-des">Ante las mayores posibilidades para los ayuntamientos de dar vigencia a los principios de autonomía y de libertad, por medio del ejercicio de los derechos y obligaciones que se le otorgan al municipio y su mayor capacidad de
                                gobierno y administración, todos los aspectos relacionados con el ayuntamiento, su integración, sus funciones específicas y las de sus miembros, su organización política y administrativa, así como las funciones de las autoridades
                                auxiliares se convierte en un aspecto fundamental para la gestión eficiente.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Análisis de necesidades, prioridades y definición de estrategias </div>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Automatización de procesos administrativos</div>
                        </div>
                    </li>
                    <li class="timeline-block inverse-timeline wow fadeInRight" data-wow-delay=".2s">
                        <div class="event-details">
                            <h4 class="event-heading">Planeación del Desarrollo Municipal</h4> <br>
                            <p class="text-des">La planeación es una herramienta de racionalización de los recursos, donde es posible identificar y cuantificar el tamaño de los retos a enfrentar y con base a una estimación real de recursos, priorizar aquello que es de mayor
                                interés solucionar, ubicando las acciones a realizar en el tiempo y en el espacio.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Diseño, desarrollo y puesta en marcha de proyectos estratégicos </div>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Elaboración del Plan de Desarrollo Municipal</div>
                        </div>
                    </li>
                    <li class="timeline-block inverse-timeline wow fadeInRight" data-wow-delay=".6s">
                        <div class="event-details">
                            <h4 class="event-heading">Presupuesto de Egresos Municipal</h4> <br>
                            <p class="text-des">Los programas del gobierno municipal tienen una base para su operación; ésta es el Presupuesto de Egresos. La importancia que reviste una ordenación completa y previsora de los gastos que el ayuntamiento debe realizar en el
                                periodo de un año para el cumplimiento de los cometidos del municipio, determina la elaboración del Presupuesto de Egresos, mismo que se orienta hacia un adecuado manejo de los fondos financieros municipales.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Presupuesto basado en resultados</div>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Sistema de evaluación del desempeño</div>
                        </div>
                    </li>
                    <li class="timeline-block simple-timeline wow fadeInLeft" data-wow-delay=".4s">
                        <div class="event-details">
                            <h4 class="event-heading">Ley de Ingresos Municipales</h4> <br>
                            <p class="text-des">El cumplimiento de las funciones encomendadas al municipio requiere de la existencia de recursos eficientes y oportunos. La obtención de recursos es posible gracias a la Ley de Ingresos, ya que en ella se expone una relación
                                completa de todos los conceptos de ingresos municipales que deberán ser cobrados por la tesorería para sufragar los gastos del municipio.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Cálculo de las fórmulas de distribución de participaciones por fondo (verificar la correcta administración de recursos)</div>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Análisis de recaudación de predial y agua</div>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Análisis y proyecciones de ingresos ordinarios</div>
                        </div>
                    </li>
                    <div class="row timeline-year">
                        <div class="col-12">
                            <div class="t-year">
                                <h4 class="heading"></h4>
                            </div>
                        </div>
                    </div>
                    <li class="timeline-block simple-timeline wow fadeInLeft" data-wow-delay=".8s">
                        <div class="event-details">
                            <h4 class="event-heading">Contabilidad y Cuenta Pública Municipal</h4> <br>
                            <p class="text-des">Sin duda alguna, la contabilidad constituye una de las principales funciones que tienen que cumplir las tesorerías municipales, para llevar un registro y control eficiente y oportuno de las operaciones que originan ingresos
                                y gastos en el municipio.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Entrega de información con formatos armonizados</div>
                        </div>
                    </li>
                    <li class="timeline-block inverse-timeline wow fadeInRight" data-wow-delay=".10s">
                        <div class="event-details">
                            <h4 class="event-heading">Transparencia de la Gestión Municipal</h4> <br>
                            <p class="text-des">Garantizar el derecho de acceso a la información pública en posesión de cualquier autoridad, es una disposición constitucional, toda la información generada, obtenida, adquirida, transformada o en posesión de los sujetos obligados
                                es considerada un derecho humano, accesible a cualquier persona y sólo podrá ser clasificada excepcionalmente como reservada de forma temporal por razones de interés público y seguridad nacional o bien, como confidencial.
                            </p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Cumplimiento con las disposiciones de transparencia</div>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Desarrollo de página de internet con información apegada a disposiciones del marco jurídico</div>
                        </div>
                    </li>
                    <li class="timeline-block inverse-timeline wow fadeInLeft" data-wow-delay=".12s">
                        <div class="event-details">
                            <h4 class="event-heading">Programación Municipal</h4> <br>
                            <p class="text-des">La programación municipal debe ser práctica habitual del proceso de planeación, mediante la cual los gobiernos municipales podrán prever las acciones que deberán desarrollarse para alcanzar los objetivos establecidos.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Desarrollo de Indicadores programáticos</div>
                        </div>
                    </li>
                    <li class="timeline-block simple-timeline wow fadeInLeft" data-wow-delay=".4s">
                        <div class="event-details">
                            <h4 class="event-heading">Control de la Gestión Municipal</h4> <br>
                            <p class="text-des">El gobierno municipal necesita de un aparato administrativo eficiente y responsable en la ejecución de las tareas encomendadas al ayuntamiento, para que éste pueda realizar su gestión en forma eficaz y de compromiso social.
                                Lo anterior implica la existencia de un órgano especializado que lleve a cabo las tareas de supervisión, evaluación y control de las acciones ejecutadas por las dependencias administrativas, en el cumplimiento de las actividades
                                del gobierno municipal.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Sistema de evaluación del desempeño</div>
                        </div>
                    </li>
                    <div class="row timeline-year">
                        <div class="col-12">
                            <div class="t-year">
                                <h4 class="heading"></h4>
                            </div>
                        </div>
                    </div>
                    <li class="timeline-block simple-timeline wow fadeInLeft">
                        <div class="event-details">
                            <h4 class="event-heading">Información en la Administración Municipal</h4> <br>
                            <p class="text-des">Es frecuente que las autoridades municipales se enfrenten a ciertos problemas con un grado considerable de incertidumbre en la toma de decisiones, es posible que esta situación sea producto de la falta de información, aunque
                                pueda disponerse de muchos datos, sin embargo, el tener muchos datos sobre un asunto especifico no es suficiente para decidir correctamente, es necesario procesar esos datos en una información clara.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Concentración de información municipal </div>
                        </div>
                    </li>
                    <li class="timeline-block inverse-timeline wow fadeInRight" data-wow-delay=".2s">
                        <div class="event-details">
                            <h4 class="event-heading">Administración y Reglamentación del Desarrollo Urbano Municipal</h4> <br>
                            <p class="text-des">Organizar el crecimiento y desarrollo de sus centros de población, atendiendo para ello las características propias de su medio ambiente y respondiendo a las demandas que presente la comunidad. El desarrollo urbano no es exclusivo
                                de las ciudades, ya que su carácter es universal y se manifiesta también en aquellos municipios que tienen características rurales y que experimentan la creación de nuevos asentamientos, a la manera de un pueblo o una pequeña
                                ciudad.
                            </p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Estudios desarrollo urbano</div>
                        </div>
                    </li>
                    <li class="timeline-block inverse-timeline wow fadeInRight" data-wow-delay=".6s">
                        <div class="event-details">
                            <h4 class="event-heading">Protección Ecológica en los Municipios</h4> <br>
                            <p class="text-des">Garantizar el desarrollo armónico de las actividades humanas con su entorno físico. El control de la contaminación ambiental y la protección ecológica son también materias de la administración municipal, por ello, en este nivel
                                de gobierno se hace indispensable la definición de programas y acciones encaminadas a proteger y conservar el equilibrio ecológico de su territorio.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Estudios de medio ambiente</div>
                        </div>
                    </li>
                    <li class="timeline-block simple-timeline wow fadeInLeft" data-wow-delay=".4s">
                        <div class="event-details">
                            <h4 class="event-heading">Capacitación del Personal Municipal</h4> <br>
                            <p class="text-des">La capacitación es el proceso de enseñanza y actualización de conocimientos durante el desempeño de la funciona publica; también es el medio para elevar el nivel de conocimiento de los servidores públicos municipales que les
                                permita una mejoría en su trabajo y su cultura.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Capacitación a Síndico y Contralor, piezas claves del éxito de la administración municipal</div>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Capacitación Entrega Recepción</div>
                        </div>
                    </li>
                    <div class="row timeline-year">
                        <div class="col-12">
                            <div class="t-year">
                                <h4 class="heading"></h4>
                            </div>
                        </div>
                    </div>
                    <li class="timeline-block simple-timeline wow fadeInLeft" data-wow-delay=".8s">
                        <div class="event-details">
                            <h4 class="event-heading">Asistencia Social Municipal</h4> <br>
                            <p class="text-des">Por asistencia social se entiende "El conjunto de acciones tendientes a convertir en positivas las circunstancias adversas que puedan impedir al hombre su realización como individuo, como miembro de una familia y de la comunidad;
                                así como la protección física, mental y social a personas en estado de abandono, incapacidad o minusvalía, en tanto se logre una solución satisfactoria a su situación.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Padrones de beneficiarios</div>
                        </div>
                    </li>
                    <li class="timeline-block inverse-timeline wow fadeInRight" data-wow-delay=".10s">
                        <div class="event-details">
                            <h4 class="event-heading">Financiamiento y manejo de la deuda</h4> <br>
                            <p class="text-des">Mantener la hacienda pública saneada debe ser objetivo central de la administración municipal, pero finanzas públicas sanas no implica cancelar la posibilidad de acceder al crédito como fuente de recursos, especialmente, cuando
                                estos resultan insuficientes para financiar los programas de inversión previstos en sus planes de trabajo, pueden entonces acudir a los ingresos extraordinarios provenientes del crédito, simplemente cuidando que no se rebase
                                la capacidad de pago, y siempre buscando las mejores condiciones de contratación.
                            </p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Análisis de viabilidad financiera para la contratación de deuda</div>
                        </div>
                    </li>
                    <li class="timeline-block inverse-timeline wow fadeInLeft" data-wow-delay=".12s">
                        <div class="event-details">
                            <h4 class="event-heading">Turismo</h4> <br>
                            <p class="text-des">En entornos de tan amplia riqueza natural, cultural y arquitectónica como los prevalecientes en nuestra entidad, es difícil no encontrar atractivos turísticos, los que varían según su tamaño, su dificultad de acceso, y hasta
                                por el tipo de servicios que ofrecen. Se suele encontrar servicios de escasa calidad o aislados y dispersos cuando la exigencia del mercado es una concentración de servicios turísticos que requiere un viajero durante el
                                tiempo que permanece dentro de su territorio. Lo que requiere de un esquema organizativo y una superestructura administrativa, integrada por las unidades turísticas, organizaciones de la empresa privada y organismos gubernamentales,
                                pero esta integración difícilmente ocurre de manera espontánea, requiere del protagonismo de la autoridad municipal como el ente más cercano a la problemática y la necesidad para coordinar el funcionamiento del espacio
                                turístico.
                            </p>
                        </div>
                    </li>
                    <li class="timeline-block simple-timeline wow fadeInLeft" data-wow-delay=".4s">
                        <div class="event-details">
                            <h4 class="event-heading">Proyectos como base de obtención de recursos</h4> <br>
                            <p class="text-des">Uno de los grandes desafíos que tienen los municipios, está relacionado con la disponibilidad de una cartera permanente de proyectos, que sean viables financieramente. Se tienen multitud de ideas, pero pocas realmente aterrizadas
                                y expuestas en un documento técnico, lo que dificulta su realización. Existen una gran diversidad de posibles fuentes de recursos para financiar proyectos de infraestructura, pero en general los municipios en Hidalgo no
                                cuentan con proyectos analizados, de manera que los mismos tengan un impacto social y económico. Realizar estudios de pre factibilidad de los proyectos ayuda, a evaluar las distintas alternativas que rodean a un proyecto,
                                incluido el tema de las fuentes de financiamiento. Es una verdadera necesidad el que los municipios realicen mejores elecciones de sus proyectos de infraestructura o servicios que tanto necesitan.</p>
                            <div class="event-time"> <i class="fas fa-caret-right"></i> Evaluación de proyectos de inversión</div>
                        </div>
                    </li>
                    <div class="row timeline-year">
                        <div class="col-12">
                            <div class="t-year">
                                <h4 class="heading"></h4>
                            </div>
                        </div>
                    </div>
                    <li class="timeline-block simple-timeline wow fadeInLeft">
                        <div class="event-details">
                            <h4 class="event-heading">Administración del Personal Municipal</h4> <br>
                            <p class="text-des">Los principales elementos de la administración de personal y sus etapas, la clasificación de los servidores públicos municipales, algunos elementos que intervienen en el desempeño de funciones de los servidores públicos municipales,
                                las actividades básicas de la administración de personal, las situaciones bajo las cuales termina o prescribe la relación laboral y algunas ideas para resolver conflictos laborales son aspectos fundamentales para un mejor
                                aprovechamiento de los recursos humanos al servicio del municipio y el cumplimiento de las diferentes obligaciones y funciones de los gobiernos municipales.</p>
                        </div>
                    </li>
                    <li class="timeline-block inverse-timeline wow fadeInRight" data-wow-delay=".2s">
                        <div class="event-details">
                            <h4 class="event-heading">Administración de los Servicios Públicos Municipales</h4> <br>
                            <p class="text-des">Apoyo al ayuntamiento en la operación de los servicios públicos; proporcionar los elementos básicos que deberán ser considerados en su prestación como son: el marco jurídico, la identificación de los servicios públicos que
                                requiere la comunidad, los principios que deberán observarse y los elementos necesarios para su mejor prestación, así como también algunas de las formas de administración y financiamiento de dichos servicios.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!--timeline sec end-->