<!--Page Header-->
<section id="main-banner-page" class="position-relative page-header blog-header parallax section-nav-smooth">
    <div class="overlay overlay-dark opacity-7 z-index-1"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div class="page-titles whitecolor text-center padding_top padding_bottom">
                    <h2 class="font-xlight">No te pierdas</h2>
                    <h2 class="font-bold">Ningún articulo</h2>
                    <h3 class="font-light pt-2">Estamos seguros que te van a interesar</h3>
                </div>
            </div>
        </div>
        <div class="gradient-bg title-wrap">
            <div class="row">
                <div class="col-lg-12 col-md-12 whitecolor">
                    <h3 class="float-left">Blog</h3>
                    <ul class="breadcrumb top10 bottom10 float-right">
                        <li class="breadcrumb-item hover-light"><a [routerLink]="['/home']">Home</a></li>
                        <li class="breadcrumb-item hover-light">Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Page Header ends -->

<!--BLOG SECTION-->
<section id="ourblog" class="padding_top padding_bottom_half">
    <div class="container">
        <h2 class="d-none">Blog</h2>
        <div class="row">
            <div class="col-lg-8" id="blog" *ngIf="articulos.length === 0 || cargando">
                <facebook-content-loader></facebook-content-loader>
                <facebook-content-loader></facebook-content-loader>
                <facebook-content-loader></facebook-content-loader>
                <facebook-content-loader></facebook-content-loader>
            </div>
            <div class="col-lg-8" id="blog" *ngIf="articulos.length > 0 && !cargando">
                <article class="blog-item heading_space wow fadeIn text-center text-md-left" data-wow-delay="300ms" *ngFor="let articulo of articulos; let i = index; let impar = odd; let par = even;">
                    <div class="image"><img [src]="articulo.imagenArticulo | noimage | domseguro" alt="blog" class="border_radius bottom40"></div>
                    <h3 class="darkcolor font-light bottom10 top30"> <a [routerLink]="['/blog-detail', articulo.idArticulo]">{{articulo.titulo}}</a></h3>
                    <ul class="commment bottom20">
                        <li><a><i class="fas fa-calendar"></i> {{articulo.fechaCreacion | dateTimeFormatFilter : "DD MMMM" | titlecase }}</a></li>
                        <li><a><i class="fas fa-comments"></i> {{articulo.totalComentarios}}</a></li>
                        <li><a><i class="fas fa-user"></i> {{articulo.autor}}</a></li>
                    </ul>
                    <div class="bottom10 top30">
                        <a class="button btn-yellow" [routerLink]="['/blog-detail', articulo.idArticulo]">Leer más ...</a>
                    </div>
                </article>
                <div class="col-sm-12 nopadding">
                    <app-pagination *ngIf="totalPaginas && paginaActual" [totalPaginas]="totalPaginas" [paginaActual]="paginaActual"></app-pagination>
                </div>
            </div>
            <div class="col-lg-4">
                <aside class="sidebar padding-bottom whitebox">
                    <div class="center-btn-filter">
                        <button *ngIf="categoriaSelect && categoriaSelect.idCategoria" class="button btn-yellow mb-3" type="button" (click)="selectCat(categoriaSelect)">
                            <i class="fas fa-times"></i> Quitar filtro
                        </button>
                    </div>
                    <div class="widget heading_space_half wow fadeIn text-center text-md-left" *ngIf="autocompleteArticulos.length === 0 || cargando">
                        <bullet-list-content-loader></bullet-list-content-loader>
                    </div>
                    <div class="widget heading_space_half wow fadeIn text-center text-md-left" data-wow-delay="300ms" *ngIf="autocompleteArticulos.length > 0 && !cargando">
                        <h4 class="text-capitalize darkcolor bottom20">Buscar artículo</h4>
                        <form class="widget_search">
                            <div class="input-group">
                                <label for="search" class="d-none"></label>
                                <ng-autocomplete [data]="autocompleteArticulos" [searchKeyword]="keyword" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" placeholder="Buscar ..." (selected)='selectEvent($event)' (inputCleared)='clearedEvent()'>
                                </ng-autocomplete>

                                <ng-template #itemTemplate let-item>
                                    <a [innerHTML]="item.titulo"></a>
                                </ng-template>

                                <ng-template #notFoundTemplate let-notFound>
                                    <div>No existen registros</div>
                                </ng-template>
                            </div>
                        </form>
                    </div>
                    <div class="widget heading_space_half wow fadeIn" *ngIf="ultArticulos.length === 0 || cargando">
                        <bullet-list-content-loader></bullet-list-content-loader>
                        <bullet-list-content-loader></bullet-list-content-loader>
                    </div>
                    <div class="widget heading_space_half wow fadeIn" data-wow-delay="350ms" *ngIf="ultArticulos.length > 0 && !cargando">
                        <h4 class="text-capitalize darkcolor bottom20 text-center text-md-left">Últimos artículos</h4>
                        <div class="single_post" *ngFor="let articulo of ultArticulos; let i = index">
                            <a [routerLink]="['/blog-detail', articulo.idArticulo]" class="post"><img style="height:50px; width: 50px; object-fit: cover;" [src]="articulo.imagenArticulo | noimage | domseguro"></a>
                            <div class="text">
                                <a [routerLink]="['/blog-detail', articulo.idArticulo]">{{articulo.titulo}}</a>
                                <span>{{articulo.fechaCreacion | dateTimeFormatFilter : "MMMM DD, y" | titlecase }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="widget heading_space_half wow fadeIn text-center text-md-left" *ngIf="categorias.length === 0 || cargando">
                        <bullet-list-content-loader></bullet-list-content-loader>
                    </div>
                    <div class="widget heading_space_half wow fadeIn text-center text-md-left" data-wow-delay="400ms" *ngIf="categorias.length > 0 && !cargando">
                        <h4 class="text-capitalize darkcolor bottom20">Categorias</h4>
                        <ul class="webtags">
                            <li *ngFor="let categoria of categorias; let i = index;">
                                <a style="cursor: pointer;" [class.cat-selected]="categoriaSelect.idCategoria == categoria.idCategoria" (click)="selectCat(categoria)">{{categoria.descripcion}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="widget heading_space_half wow fadeIn text-center text-md-left" *ngIf="publicidad.length === 0 || cargando">
                        <bullet-list-content-loader></bullet-list-content-loader>
                    </div>
                    <div class="widget heading_space_half wow fadeIn text-center text-md-left" *ngIf="publicidad.length === 0 || cargando">
                        <bullet-list-content-loader></bullet-list-content-loader>
                    </div>
                    <div *ngFor="let anuncio of publicidad; let i = index;">
                        <div class="heading_space_half wow fadeIn" data-wow-delay="400ms">
                            <a [href]="anuncio.publicidad[0].url" target="_blank">
                                <img [src]="anuncio.publicidad[0].imagenesPublicidad[0] | noimage | domseguro" alt="publicidad" class="border_radius bottom40 text-center">
                            </a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</section>
<!--BLOG SECTION END-->