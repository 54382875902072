<!--Main Slider-->
<section id="main-banner-area" class="position-relative">
    <div id="revo_main_wrapper" class="rev_slider_wrapper fullwidthbanner-container m-0 p-0 bg-dark" data-alias="classic4export" data-source="gallery">
        <!-- START REVOLUTION SLIDER 5.4.1 fullwidth mode -->
        <div id="rev_main" class="rev_slider fullwidthabanner white" data-version="5.4.1">
            <ul>
                <!-- SLIDE 1 -->
                <li data-index="rs-01" data-transition="fade" data-slotamount="default" data-easein="Power100.easeIn" data-easeout="Power100.easeOut" data-masterspeed="2000" data-fsmasterspeed="1500" data-param1="01">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/banner1-3.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" class="rev-slidebg" data-no-retina>
                    <!-- LAYER NR. 1 -->
                    <div class="overlay overlay-dark opacity-7"></div>
                    <div class="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-130','-130','-110','-80']" data-width="none" data-height="none" data-type="text"
                        data-textAlign="['center','center','center','center']" data-responsive_offset="on" data-start="1000" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'>
                        <img src="assets/images/logo-complete.svg" alt="logo" class="logo-default" style="margin-top: 50px;">
                    </div>
                </li>
                <!-- SLIDE 2 -->
                <li data-index="rs-02" data-transition="fade" data-slotamount="default" data-easein="Power3.easeInOut" data-easeout="Power3.easeInOut" data-masterspeed="2000" data-fsmasterspeed="1500" data-param1="02">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/banner1-2.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" class="rev-slidebg" data-no-retina>
                    <!-- LAYER NR. 1 -->
                    <div class="overlay overlay-dark opacity-7"></div>
                    <div class="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-130','-130','-110','-80']" data-width="none" data-height="none" data-type="text"
                        data-textAlign="['center','center','center','center']" data-responsive_offset="on" data-start="1000" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'>
                        <h2 class="text-capitalize font-xlight whitecolor text-center heading-title-small">Gabinete de Expertos</h2>
                    </div>
                    <!-- LAYER NR. 2 -->
                    <div class="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-70','-70','-50','-20']" data-width="none" data-height="none" data-type="text"
                        data-textAlign="['center','center','center','center']" data-responsive_offset="on" data-start="1000" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'>
                        <h2 class="text-capitalize font-bold whitecolor text-center">en Soluciones Públicas</h2>
                    </div>
                    <!-- LAYER NR. 3 -->
                    <div class="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-10','-10','10','40']" data-width="none" data-height="none" data-type="text"
                        data-textAlign="['center','center','center','center']" data-responsive_offset="on" data-start="1500" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'>
                        <h2 class="text-capitalize font-xlight whitecolor text-center heading-title-small">Información y acción</h2>
                    </div>
                    <!-- LAYER NR. 4 -->
                    <div class="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['40','40','60','90']" data-width="none" data-height="none" data-whitespace="nowrap"
                        data-type="text" data-textAlign="['center','center','center','center']" data-responsive_offset="on" data-start="2000" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":1500,"to":"o:1;","delay":2000,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'>
                        <h4 class="whitecolor font-xlight text-center">para el desarrollo</h4>
                    </div>
                </li>
                <!-- SLIDE 3 -->
                <li data-index="rs-03" data-transition="fade" data-slotamount="default" data-easein="Power3.easeInOut" data-easeout="Power3.easeInOut" data-masterspeed="2000" data-fsmasterspeed="1500" data-param1="03">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/banner1-1.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="10" class="rev-slidebg" data-no-retina>
                    <!-- LAYER NR. 1 -->
                    <div class="overlay overlay-dark opacity-7"></div>
                    <div class="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-130','-130','-110','-80']" data-width="none" data-height="none" data-type="text"
                        data-textAlign="['center','center','center','center']" data-responsive_offset="on" data-start="1000" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'>
                        <h2 class="text-capitalize font-xlight whitecolor text-center heading-title-small">Nuestro principal valor</h2>
                    </div>
                    <!-- LAYER NR. 2 -->
                    <div class="tp-caption tp-resizeme" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']" data-voffset="['-70','-70','-50','-20']" data-width="none" data-height="none" data-type="text"
                        data-textAlign="['center','center','center','center']" data-responsive_offset="on" data-start="1000" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'>
                        <h2 class="text-capitalize font-bold whitecolor text-center">La ética</h2>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <ul class="social-icons-simple revicon white">
        <li class="d-table"><a href="https://www.facebook.com/GESPUBLICAS/" target="_blank" class="facebook"><i class="fab fa-facebook-f"></i></a> </li>
        <li class="d-table"><a href="https://twitter.com/GesPublicas" target="_blank" class="twitter"><i class="fab fa-twitter"></i> </a> </li>
        <li class="d-table"><a href="https://www.linkedin.com/in/GesPublicas" target="_blank" class="linkedin"><i class="fab fa-linkedin-in"></i> </a> </li>
        <li class="d-table"><a href="https://www.instagram.com/gespublicas" target="_blank" class="insta"><i class="fab fa-instagram"></i> </a> </li>
    </ul>
</section>
<!--Main Slider ends -->
<!--Some Services-->
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div id="services-slider" class="owl-carousel">
                <div class="item">
                    <div class="service-box">
                        <span class="bottom25"><i class="fas fa-chart-line"></i></span>
                        <h3 class="bottom10 text-nowrap"><a href="javascript:void(0)">Análisis</a></h3>
                    </div>
                </div>
                <div class="item">
                    <div class="service-box">
                        <span class="bottom25"><i class="fas fa-stethoscope"></i></span>
                        <h3 class="bottom10"><a href="javascript:void(0)">Diagnósticos</a></h3>
                    </div>
                </div>
                <div class="item">
                    <div class="service-box">
                        <span class="bottom25"><i class="fas fa-project-diagram"></i></span>
                        <h3 class="bottom10"><a href="javascript:void(0)">Proyectos</a></h3>
                    </div>
                </div>
                <div class="item">
                    <div class="service-box">
                        <span class="bottom25"><i class="fas fa-tasks"></i></span>
                        <h3 class="bottom10"><a href="javascript:void(0)">Evaluaciones</a></h3>
                    </div>
                </div>
                <div class="item">
                    <div class="service-box">
                        <span class="bottom25"><i class="fas fa-laptop-code"></i></span>
                        <h3 class="bottom10"><a href="javascript:void(0)">Sistemas informáticos</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Some Services ends-->
<!-- Mobile Apps -->
<section id="our-apps" class="padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-7 col-sm-12">
                <div class="heading-title bottom30 wow fadeInLeft" data-wow-delay="200ms">
                    <h2 class="bottom30 darkcolor font-normal text-center text-md-left">Nuestros servicios</h2>
                </div>
            </div>
            <div class="col-lg-6 col-md-5 col-sm-12 wow fadeInRight" data-wow-delay="200ms">
                <p class="heading_space mt-n3 mt-sm-0 text-center text-md-left">Las administraciones públicas enfrentan grandes retos ya que las atribuciones a desarrollar son demasiadas y es muy dificil convertirse en poco tiempo en un experto que permita la atención eficiente de todos los temas.
                </p>
            </div>
        </div>
        <div class="row d-flex align-items-center" id="app-feature">
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="text-center text-md-left">
                    <div class="feature-item mt-1 wow fadeInLeft" data-wow-delay="200ms">
                        <div class="icon"><i class="fas fa-edit"></i></div>
                        <div class="text">
                            <h4 class="bottom15">
                                <span class="d-inline-block">Planes de desarrollo</span>
                            </h4>
                        </div>
                    </div>
                    <div class="feature-item mt-5 wow fadeInLeft" data-wow-delay="250ms">
                        <div class="icon"><i class="fas fa-poll"></i></div>
                        <div class="text">
                            <h4 class="bottom15">
                                <span class="d-inline-block">Presupuesto basado en resultados</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-12 text-center">
                <div class="image feature-item d-inline-block wow fadeIn my-5 my-md-0" data-wow-delay="400ms">
                    <img src="assets/images/services.jpg" alt="servicios">
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <div class="text-center text-md-right">
                    <div class="feature-item mt-1 wow fadeInRight" data-wow-delay="200ms">
                        <div class="icon"><i class="fas fa-tasks"></i></div>
                        <div class="text">
                            <h4 class="bottom15">
                                <span class="d-inline-block">Sistema de evaluación del desempeño</span>
                            </h4>
                        </div>
                    </div>
                    <div class="feature-item mt-5 wow fadeInRight" data-wow-delay="250ms">
                        <div class="icon"><i class="fas fa-laptop-code"></i></div>
                        <div class="text">
                            <h4 class="bottom15">
                                <span class="d-inline-block">Desarrollo de sistemas de información</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Mobile Apps ends-->
<!-- Work Process-->
<section id="our-process" class="padding bgprimary">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 text-center">
                <div class="heading-title whitecolor wow fadeInUp" data-wow-delay="300ms">
                    <h2 class="font-normal">Gobiernos municipales</h2>
                    <span> Sigue los pasos para obtener un diagnóstico general</span>
                    <h2 class="font-normal"> Sin costo </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <ul class="process-wrapp">
                <li class="whitecolor wow fadeIn" data-wow-delay="300ms">
                    <span class="pro-step bottom20">01</span>
                    <p class="fontbold bottom20">Contactar</p>
                    <p class="mt-n2 mt-sm-0">Envía un correo solicitando el cuestionario</p>
                </li>
                <li class="whitecolor wow fadeIn" data-wow-delay="400ms">
                    <span class="pro-step bottom20">02</span>
                    <p class="fontbold bottom20">Completar</p>
                    <p class="mt-n2 mt-sm-0">Completa la información del cuestionario</p>
                </li>
                <li class="whitecolor wow fadeIn active" data-wow-delay="500ms">
                    <span class="pro-step bottom20">03</span>
                    <p class="fontbold bottom20">Enviar</p>
                    <p class="mt-n2 mt-sm-0">Envía el cuestionario a nuestro correo</p>
                </li>
                <li class="whitecolor wow fadeIn" data-wow-delay="600ms">
                    <span class="pro-step bottom20">04</span>
                    <p class="fontbold bottom20">Analizar</p>
                    <p class="mt-n2 mt-sm-0">Realizaremos el análisis y tu ponderación</p>
                </li>
                <li class="whitecolor wow fadeIn" data-wow-delay="700ms">
                    <span class="pro-step bottom20">05</span>
                    <p class="fontbold bottom20">Obtener</p>
                    <p class="mt-n2 mt-sm-0">En un par de días tendrás tu resultado</p>
                </li>
            </ul>
        </div>
    </div>
</section>
<!--Work Process ends-->
<!-- Counters -->
<section id="bg-counters" class="padding bg-counters parallax">
    <div class="container">
        <div class="row align-items-center text-center">
            <div class="overlay overlay-dark opacity-6 z-index-0">
                <div class="heading-title whitecolor wow fadeInUp" data-wow-delay="300ms">
                    <h2 class="font-light bottom25 top20"> Nuestra experiencia </h2>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 bottom10">
                <div class="counters whitecolor top10 bottom10">
                    <h3><span class="count_nums font-light" data-to="100" data-speed="1500"> </span>%</h3>
                </div>
                <h3 class="font-light whitecolor top20">Docencia</h3>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 bottom10">
                <div class="counters whitecolor top10 bottom10">
                    <h3><span class="count_nums font-light" data-to="98" data-speed="1500"> </span>%</h3>
                </div>
                <h3 class="font-light whitecolor top20">Administración</h3>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 bottom10">
                <div class="counters whitecolor top10 bottom10">
                    <h3><span class="count_nums font-light" data-to="98" data-speed="1500"> </span>%</h3>
                </div>
                <h3 class="font-light whitecolor top20">Economía</h3>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 bottom10">
                <div class="counters whitecolor top10 bottom10">
                    <h3><span class="count_nums font-light" data-to="100" data-speed="1500"> </span>%</h3>
                </div>
                <h3 class="font-light whitecolor top20">Planeación</h3>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 bottom10">
                <div class="counters whitecolor top10 bottom10">
                    <h3><span class="count_nums font-light" data-to="98" data-speed="1500"> </span>%</h3>
                </div>
                <h3 class="font-light whitecolor top20">Presupuestos</h3>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 bottom10">
                <div class="counters whitecolor top10 bottom10">
                    <h3><span class="count_nums font-light" data-to="95" data-speed="1500"> </span>%</h3>
                </div>
                <h3 class="font-light whitecolor top20">Informática</h3>
            </div>
        </div>
    </div>
</section>
<!-- Counters ends-->
<!-- Contact US -->
<section id="stayconnect" class="bglight position-relative">
    <div class="container">
        <div class="contactus-wrapp">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="heading-title wow fadeInUp text-center text-md-left" data-wow-delay="300ms">
                        <h3 class="darkcolor bottom20">Escríbenos tus dudas y comentarios</h3>
                    </div>
                </div>
                <div class="col-md-12 col-sm-12">
                    <form class="getin_form wow fadeInUp" data-wow-delay="400ms" onsubmit="return false;">
                        <div class="row">
                            <div class="col-md-12 col-sm-12" id="result"></div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="userName" class="d-none"></label>
                                    <input class="form-control" type="text" placeholder="Nombre" required id="userName" name="userName">
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="companyName" class="d-none"></label>
                                    <input class="form-control" type="text" placeholder="Ciudad ó Municipio" id="companyName" name="companyName">
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <div class="form-group">
                                    <label for="email" class="d-none"></label>
                                    <input class="form-control" type="email" placeholder="Correo" required id="email" name="email">
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-2">
                                <div class="form-group">
                                    <label for="userMessage" class="d-none"></label>
                                    <textarea class="form-control" name="userMessage" rows="6" placeholder="Queremos conocer tus inquietudes"></textarea>
                                </div>
                            </div>
                            <div class="col-md-12 col-sm-6" style="justify-content: center; align-items: center; display: flex;">
                                <div class="col-md-6 col-sm-6">
                                    <button type="submit" class="button gradient-btn w-100" id="submit_btn">Enviar</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact US ends -->