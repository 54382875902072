import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noimage'
})
export class NoImagePipe implements PipeTransform {

  transform(image: any): string {
    if (!image) {
      return 'assets/img/noimage.png'
    }

    if(`${image.base64}` === "null"){
      return 'assets/img/noimage.png'
    }

    return `data:image/${image.extension.split('.').pop()};base64,${image.base64}`
    
  }
}
